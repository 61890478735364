import React from 'react'

const CollectedAFDefinition = () => {
  return (
    <div className="w-full mt-4 mx-2 md:mx-8 overflow-scroll px-4">
      <p className="text-base mb-2 text-GREY">
        <span className='text-ORANGE'>CollectedAF</span> is revolutionizing social interactions by merging vintage charm with modern technology. <span className='select-none'>Imagine effortlessly connecting with people around you—at the gym, during a shopping spree, or in your workplace. With CollectedAF, making instant connections is now a reality, eliminating the need for awkward introductions and curiosity about others’ interests.</span> 🤔 No worries, we’ve got you covered.
      </p>
      <p className="text-base mb-2 text-GREY">
        At <span className='text-ORANGE'>CollectedAF</span>, we focus on <span className='select-none'>attributes, not bios. Showcase who you truly are with tags like #<span className='text-GREEN'>DogLover</span>, #<span className='text-GREEN'>GameOfThronesSeason8Hater</span>, or #<span className='text-GREEN'>Single</span>. These attributes define you! Someone finds your attribute intriguing? Boom! On CollectedAF, that makes you</span> <span className='text-BLUE_AF'> "funnyAF"</span>. <span className='select-none'>Give, receive, and add attributes to become verifiably collectable!</span>
      </p>
      <p className="text-base mb-2 text-GREY">
        <span className='select-none'>Collect people by simply walking by them! Create your own collections or explore our suggested collections. Think of it like PEOPLE POKÉMON—collect and connect with ease!</span>
      </p>
      <p className="text-base mb-2 text-GREY">
        But that’s not all—<span className='text-ORANGE'>CollectedAF</span> seamlessly integrates with businesses to transform customer engagement. Imagine having access to customers instantly as they walk into your store. Engage with them based on their interests, offer personalized promotions, and enhance their shopping experience in real-time. Businesses can leverage our platform for innovative networking solutions, enhanced brand exposure, targeted marketing, and real-time engagement insights.
      </p>
      <p className="text-base mb-2 text-GREY">
        Join the revolution with <span className='text-ORANGE'>CollectedAF</span> and experience the future of social networking and business integration today.
      </p>
    </div>
  );
}

export default CollectedAFDefinition