import * as React from "react";
// import usePlacesAutocomplete, {
//     getGeocode,
//     getLatLng,
//   } from "use-places-autocomplete";

const Places = ({setOffice}) => {
    // const { ready, value, setValue, suggestions: {status, data}, clearSuggestions} = usePlacesAutocomplete()
  return (
    <div>  
        {/* <Combobox>
    <ComboboxInput aria-labelledby="demo" />
    <ComboboxPopover>
      <ComboboxList aria-labelledby="demo">
        <ComboboxOption value="Apple" />
        <ComboboxOption value="Banana" />
        <ComboboxOption value="Orange" />
        <ComboboxOption value="Pineapple" />
        <ComboboxOption value="Kiwi" />
      </ComboboxList>
    </ComboboxPopover>
  </Combobox> */}
  </div>
  )
}

export default Places