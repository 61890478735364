import React, { useContext, Dispatch, SetStateAction } from "react";
// import React, { useContext, Dispatch, SetStateAction } from "react";
// import { UserContext } from "../context/user-context";
import { TitleContext } from "../context/title-context";
import logo from "../assets/CollectedAF_Logo.png";


const Title = () => {
// const { user, setUser } = useContext(UserContext)  // exmple of how to implement the use context
const { titlePressed, setTitlePressed } = useContext(TitleContext) 

  return (
    <>
      <div className="w-full pt-28 flex justify-end px-4 md:pl-44 md:pr-20 h-title-container fixed">
        <button
          onClick={() => setTitlePressed(!titlePressed)}
          className={`px-8 h-16 ${
            titlePressed ? "morphic-attribute-button-pressed" : "morphic-button"
          } md:w-title flex items-center justify-center`}
        >
          <div className="mr-4">
                  <img
                    className="hidden md:flex h-10"
                    src={logo}
                    alt=""
                  />
                </div>
          <p className=" text-lg md:text-5xl">
            COLLECTED<span className="text-GREEN">AF</span>
          </p>
        </button>
      </div>
    </>
  );
};

export default Title;
