import React, { useCallback, useEffect, useMemo, useState } from "react";
import CollectedAFContainer from "./components/collectedaf-container";
import Container from "./components/container";
import Header from "./components/header";
import Title from "./components/title";
import { TitleContext } from "./context/title-context";
import { UserContext } from "./context/user-context";
import { db } from "./firebase/firebase-manager"; // update with your path to firestore config
import { collection, getDocs } from "firebase/firestore";
import { MapContext } from "./context/map-context";

function App() {
  const [user, setUser] = useState({});
  const [places, setPlaces] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [titlePressed, setTitlePressed] = useState<Boolean>(false);
  const mapProviderValue = useMemo(
    () => ({ showMap, setShowMap }),
    [showMap, setShowMap]
  );
  const providerValue = useMemo(() => ({ user, setUser }), [user, setUser]);
  const titleProviderValue = useMemo(
    () => ({ titlePressed, setTitlePressed }),
    [titlePressed, setTitlePressed]
  );

  const getPlaces = useCallback(async () => {
    const placesSnapshot = await getDocs(collection(db, "places"));
    const placesFromDb = placesSnapshot.docs.map((doc) => doc.data());
    console.log("🚀 ~ length", placesFromDb.length);
    console.log("🚀 ~ file: App.tsx ~ line 22 ~ placesFromDb", placesFromDb);
    // setPlaces(placesFromDb);
  }, []);

  useEffect(() => {
    // getPlaces();
    console.log("make inital request");
    console.log("check local storage");
  }, [places]);

  return (
    <UserContext.Provider value={providerValue}>
      <TitleContext.Provider value={titleProviderValue}>
        <MapContext.Provider value={mapProviderValue}>
          <CollectedAFContainer>
            <Header />
            <Title />
            <Container dbPlaces={places} />
          </CollectedAFContainer>
        </MapContext.Provider>
      </TitleContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
