// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
 const firebaseConfig = {
    apiKey: "AIzaSyAiwnJPKuDYsmiChGfPhah328IMhpONb34",
    authDomain: "appcollectedafha.firebaseapp.com",
    databaseURL: "https://appcollectedafha.firebaseio.com",
    projectId: "appcollectedafha",
    storageBucket: "appcollectedafha.appspot.com",
    messagingSenderId: "985362867362",
    appId: "1:985362867362:web:bced2535902e59e240e3fb",
    measurementId: "G-45FKT0HFS3",
  };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });