import React, { useContext, useState } from "react";
import { MapContext } from "../context/map-context";
import CollectedAFDefinition from "./collectedaf-definition";
import screenShot1 from "../assets/userscreenshot.png";
import screenShot2 from "../assets/profile.png";

const CollectedExperience = () => {
  const { showMap, setShowMap } = useContext(MapContext);
  const [showWhatWeAreDoing, setShowWhatWeAreDoing] = useState(true);
  const [applicationDescription, setApplicationDescription] = useState(false);

  const toggleHeckWedoin = () => {
    setApplicationDescription(false);
    setShowWhatWeAreDoing(!showWhatWeAreDoing);
  };

  const togglePartnerLogin = () => {
    setShowWhatWeAreDoing(false);
    setApplicationDescription(!applicationDescription);
  };

  const toggleMap = () => {
    setShowWhatWeAreDoing(false);
    setApplicationDescription(false);
    setShowMap(!showMap);
  };

  return (
    <>
      <div className="w-full h-full flex flex-col">
        <div className="w-full flex-row">
          {/* if user is a partner we display this login */}
          {true && (
            <button
              onClick={() => togglePartnerLogin()}
              className={
                applicationDescription
                  ? "text-center mt-8 text-ORANGE  text-BLUE_AF mx-2 md:mx-8 w-68  px-4 py-2"
                  : "text-center mt-8 morphic-attribute-button text-GREY mx-2 md:mx-8 w-68  px-4 py-2"
              }
            >
              collectedAF App
            </button>
          )}
          {false && (
            <button
              onClick={() => toggleMap()}
              className={
                showMap
                  ? "text-center mt-8 text-ORANGE  text-BLUE_AF mx-2 md:mx-8 w-68  px-4 py-2"
                  : "text-center mt-8 morphic-attribute-button text-GREY mx-2 md:mx-8 w-68  px-4 py-2"
              }
            >
              Map
            </button>
          )}
          <button
            onClick={() => toggleHeckWedoin()}
            className={
              showWhatWeAreDoing
                ? "text-center text-sm md:text-base mt-8 text-BLUE_AF button-radius  border-GREY mx-2 md:mx-8 w-68  px-4 py-2"
                : "text-center mt-8 button-radius morphic-attribute-button text-GREY mx-2 md:mx-8 w-68  px-4 py-2"
            }
          >
            What in the heck Are We Doing?
          </button>
        </div>
        {showWhatWeAreDoing && <CollectedAFDefinition />}
        {applicationDescription && (
          <div className="w-full h-full grid grid-cols-1 md:grid-cols-2 gap-8 items-center justify-center pt-4">
            <div className="hidden md:flex items-center justify-start">
              <img
                className="w-56 shadow-lg shadow-BLUE_AF"
                src={screenShot1}
                alt=""
              />
            </div>
            <div className="flex items-center justify-start">
              <img
                className="w-28 md:w-56 shadow-lg shadow-ORANGE"
                src={screenShot2}
                alt=""
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CollectedExperience;
