import React from 'react'

const CollectedAFContainer = ({children}) => {
  return (
    <div className="overflow-scroll app-font absolute bg-fixed bg-collectedBG h-screen w-screen bg-no-repeat bg-cover flex flex-col items-center">
        {children}
    </div>
  )
}

export default CollectedAFContainer