import { addDoc, collection } from 'firebase/firestore';
import React, { useState } from 'react';
import { db } from '../firebase/firebase-manager';

interface IAmbassador {
    setView: any
}
const AmbassadorExperience = ({setView}: IAmbassador) => {
    const [loading, setLoading] = useState(false);
    const [formComplete, setFormComplete] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [business, setPartnership] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [reason, setReason] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        setLoading(true);
        const timestamp = new Date().toLocaleString();
        await createAmbassador({ firstName, lastName, business, phone, reason, date: timestamp });
        // Reset form fields after submission
        setFirstName('');
        setLastName('');
        setPartnership('');
        setPhone('');
        setEmail('');
        setReason('');
        setLoading(false);
    };

    const canSubmit = firstName && lastName && business && phone && !loading;


    const createAmbassador = async (ambassador) => {
        try {
            await addDoc(collection(db, "partners"), ambassador);
            console.log("Ambassador added successfully");
            setFormComplete(true);
        } catch (error) {
            console.error("Error adding ambassador: ", error);
        }
    };

    return (
        <div className="w-4/5 mx-auto mt-2 mb-8 h-full z-10" style={{maxWidth: 600}}>
            <div className="flex w-full justify-between mb-4 items-center">
            <p className=" text-sm text-xl">
            <span className="text-BLUE_AF font-bold">Business</span><span className="text-GREEN">AF</span>
          </p>
            <button onClick={() => setView('icon')} className="morphic-attribute-button text-white w-1/4 h-10 px-4 py-2 justify-start">Back</button>
            </div>
        {!formComplete ?   <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="firstName" className={`block text-sm font-medium ${firstName ? 'text-GREEN' :'text-gray-700'}`}>First Name</label>
                    <input type="text" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="border-2 border-BLUE_AF rounded-md text-DARK_GREY  md:mx-0 w-full px-4 py-2" />
                </div>
                <div>
                    <label htmlFor="lastName" className={`block text-sm font-medium  ${lastName ? 'text-GREEN' :'text-gray-700'}`}>Last Name</label>
                    <input type="text" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} className="border-2 border-BLUE_AF rounded-md text-DARK_GREY  md:mx-0 w-full px-4 py-2" />
                </div>
                <div>
                    <label htmlFor="business" className={`block text-sm font-medium  ${business ? 'text-GREEN' :'text-gray-700'}`}>Business / Position</label>
                    <input type="text" id="business" value={business} onChange={(e) => setPartnership(e.target.value)} className="border-2 border-BLUE_AF rounded-md text-DARK_GREY  md:mx-0 w-full px-4 py-2" />
                </div>
                <div>
                    <label htmlFor="phone" className={`block text-sm font-medium  ${phone ? 'text-GREEN' :'text-gray-700'}`}>Phone - or - Email</label>
                    <input type="tel" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} className="border-2 border-BLUE_AF rounded-md text-DARK_GREY  md:mx-0 w-full px-4 py-2" />
                </div>
                <div>
                    <label htmlFor="reason" className={`block text-sm font-medium text-gray-700`}>Reason to connect</label>
                    <select id="reason" value={reason} onChange={(e) => setReason(e.target.value)}  className="morphic-attribute-button text-DARK_GREY  md:mx-0 mt-2 w-full px-4 py-2">
                        <option value="">Select a Reason to Partner</option>
                        <option value="Investing">Funding / Investing</option>
                        <option value="Networking">Networking Solutions</option>
                        <option value="Brand Exposure">Brand Exposure</option>
                        <option value="Marketing">Marketing</option>
                        <option value="Insights">Business Insights</option>
                        <option value="Growth">Collaborative Growth</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <button disabled={!canSubmit} type="submit" className={`${loading && 'animate-pulse'} ${canSubmit ?' text-GREEN morphic-attribute-button': 'text-BLACK_OPACITY morphic-attribute-button-pressed'} w-full px-4 py-2`}>{loading ? '...loading' : 'Submit'}</button>
            </form> : 
            <div className="h-full w-full flex flex-col items-center justify-start">
                <p className='mb-12'>Thank you for signing up to be partner with Collected AF! Your enthusiasm for innovation and community is exactly what makes this opportunity so special. Welcome to the team—let's make waves together!</p>
                <p onClick={() => setView('icon')} className="morphic-attribute-button flex h-16 text-sm text-3xl px-4 items-center justify-center">
                    
            <span className="text-GREEN font-bold ml-2 flex">completed</span><span className="text-BLUE_AF">AF</span>
          </p>
            </div>
            }
        </div>
    );
}

export default AmbassadorExperience;
