import React, {FunctionComponent} from 'react'
import logo from '../assets/collectedAF_Icon2.png'

const Header : FunctionComponent = () => {
  return (
    <div style={{minHeight: '70px'}} className='app-max-width w-full flex justify-end px-2 sm:px-20 fixed'>
<p className='text-LIGHT_GREY text-lg md:text-5xl flex md:items-center items-end'> COLLECTED<span className='text-ORANGE'>AF</span>  </p>
    </div>
  )
}

export default Header