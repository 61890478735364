import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import QR from "../assets/qr-codeAF.png";
import logo from "../assets/CollectedAF_Logo.png";
import { TitleContext } from "../context/title-context";
import PrivacyPolicy from "./privacy-policy";
import CollectedExperience from "./collected-experience";
import AmbassadorExperience from "./ambassador-experience";
import {
  GoogleMap,
  useJsApiLoader,
  useLoadScript,
  Marker,
} from "@react-google-maps/api";
import { MapContext } from "../context/map-context";
import Places from "./places";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const libraries: any = ["places"];

const Container = ({ dbPlaces }) => {
  const mapRef = useRef<any>();
  const { showMap } = useContext(MapContext);
  const { titlePressed } = useContext(TitleContext);
  const [privacy, setPrivacy] = useState(false);
  const [emailLink, setEmailLink] = useState(false);
  const googleApi = process.env.REACT_APP_GOOGLE_API_KEY;
  const [office, setOffice] = useState<any>();
  // icon privacy email ambassador
  const [view, setView] = useState<string>("icon");
  const [ambassadorPage, setAmbassadorPage] = useState<boolean>(false);
  const [updatedLatLng, setUpdatedlatlng] = useState({
    lat: 43.58752186879873,
    lng: -10.36253411892464,
  });

  const defaultOptions = {
    strokeOpacity: 0.5,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    visable: true,
  };

  const closeOptions = {
    ...defaultOptions,
    zIndex: 3,
    fillOpacity: 0.05,
    strokeColor: "#8BC34A",
    fillColor: "#8BC34A",
  };

  const middleOptions = {
    ...defaultOptions,
    zIndex: 2,
    fillOpacity: 0.05,
    strokeColor: "#FBC02D",
    fillColor: "#FBC02D",
  };

  const farOptions = {
    ...defaultOptions,
    zIndex: 1,
    fillOpacity: 0.05,
    strokeColor: "#ff5252",
    fillColor: "#ff5252",
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log('here in timeout')
  //     setUpdatedlatlng({
  //       lat: 43.5875218687987,
  //       lng: -116.3625341189246,
  //     })
  //   }, 5000)
  // }, [])
  const generatePeople = (position) => {
    const _people = [];
    for (let i = 0; i < 100; i++) {
      const direction = Math.random() < 0.5 ? -2 : 2;
      _people.push({
        lat: position.lat + Math.random() / direction,
        lng: position.lng + Math.random() / direction,
      });
    }
    return _people;
  };
  //can make custom marker with svg and icon prop
  // const svgMarker = {
  //   path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
  //   fillColor: "blue",
  //   fillOpacity: 0.6,
  //   strokeWeight: 0,
  //   rotation: 0,
  //   scale: 2,
  //   // anchor: new google.maps.Point(15, 30),
  // };

  const privacyPressed = () => {
    setPrivacy(!privacy);
  };
  const emailSupportPressed = () => {
    setEmailLink(!emailLink);
  };

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: `${googleApi}`,
    libraries,
  });

  const [map, setMap] = useState(null);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const center = useMemo(
    () => ({
      lat: 43.58752186879873,
      lng: -116.36253411892463,
    }),
    []
  );

  const options = useMemo(
    () => ({
      mapId: "2dc6e13cad8ccdd6",
      disableDefaultUi: true,
      clickableIcons: false,
    }),
    []
  );

  const onLoaded = useCallback((map) => (mapRef.current = map), []);

  const setPageView = (selected) => {
    if (selected === view) {
      setView("icon");
    } else {
      setView(selected);
    }
  };

  return (
    <>
      <div className="md:h-screen app-max-width w-full flex justify-end px-4 md:pl-44 md:pr-20 pb-12 pt-40">
        <div
        style={{minHeight: '455px'}}
          className={`pt-10 md:px-8 mt-10 overflow-y-auto z-10 ${
            titlePressed || view == 'ambassador' ? "morphic-attribute-button-pressed" : "morphic-button"
          } w-title md:w-full flex flex-col-reverse md:flex-row items-center  md:items-start justify-center`}
        >
          {!isLoaded && (
            <div className="flex items-center justify-center">
              {" "}
              ... loading{" "}
            </div>
          )}
          {isLoaded && showMap && dbPlaces && (
            <>
              <div className="flex w-full flex-col h-full">
                <div className="w-full border-4">
                  <Places
                    setOffice={(position) => {
                      setOffice(position);
                      {
                        mapRef?.current && mapRef?.current.panTo(position);
                      }
                    }}
                  />
                </div>
                <GoogleMap
                  options={options}
                  id="7beaa9e8e3d543f9"
                  zoom={4}
                  // center={center}
                  center={center}
                  mapContainerStyle={containerStyle}
                  onLoad={onLoaded}
                  onUnmount={onUnmount}
                >
                  {dbPlaces &&
                    dbPlaces.map((place) => {
                      return (
                        <Marker
                          key={place.properties.place_id}
                          position={{
                            lat: place.properties.lat,
                            lng: place.properties.lon,
                          }}
                        />
                      );
                    })}
                </GoogleMap>
              </div>
            </>
          )}
          {titlePressed ? (
            <CollectedExperience />
          ) : (
            <>
              {view === "ambassador" ? <AmbassadorExperience setView={setView}/> : 
              
            <div className="flex flex-col-reverse overflow-y-auto items-center">
              <div className="w-1/2 md:w-2/5 h-full overflow-y-auto mt-8">
                {/* {view === "ambassador" && <AmbassadorExperience/>} */}
                {view === "privacy" && <PrivacyPolicy />}
                {(view === "icon" || view === "email") && (
                    <div className="flex items-center justify-center flex-col-reverse">
                      <img
                        className="w-4/5 sm:w-3/5 md:w-full"
                        src={QR}
                        alt=""
                      />
                      {view === "email" && (
                        <a
                          href="mailto:support@collectedAF.com?subject=suport from web:"
                          className={
                            view == "email"
                              ? "text-sm text-center mt-2  text-GREEN sm:mx-2 md:mx-8 sm:px-4 py-2"
                              : "text-sm text-center mt-2 text-GREY sm:mx-2 md:mx-8 sm:px-4 py-2"
                          }
                        >
                          {" "}
                          support@collectedAF.com{" "}
                        </a>
                      )}
                    </div>
                  )}
                {/* {view === "email" && (
                  <a
                    href="mailto:support@collectedAF.com?subject=suport from web:"
                    className={
                      view == "email"
                        ? "text-sm text-center mt-2  text-GREEN sm:mx-2 md:mx-8 sm:px-4 py-2"
                        : "text-sm text-center mt-2 text-GREY sm:mx-2 md:mx-8 sm:px-4 py-2"
                    }
                  >
                    {" "}
                    support@collectedAF.com!{" "}
                  </a>
                )} */}
              </div>
              <div className="w-full md:w-1/2 flex md:flex-col justify-center my-4 md:my-0">
                <div
                  style={{ height: 140 }}
                  className="w-1/2 md:w-full flex-col flex justify-between"
                >
                  <button
                    onClick={() => setPageView("ambassador")}
                    className={
                      view == "ambassador"
                        ? "text-GREEN mx-2 md:mx-0 w-full  px-4 py-2 text-sm lg:text-base"
                        : "morphic-attribute-button text-GREY mx-2 w-full md:mx-0 px-4 py-2 text-sm lg:text-base"
                    }
                  >

                    <div className="flex flex-row w-full justify-center">
  <span className="hidden lg:flex mr-1">interested in</span>
  Partnership / Investor
</div>

                  </button>
                 <button
                    onClick={() => setPageView("privacy")}
                    className={
                      view == "privacy"
                        ? "morphic-attribute-button-pressed text-GREEN mx-2 md:mx-0 w-full  px-4 py-2"
                        : "morphic-attribute-button text-GREY mx-2 w-full md:mx-0 px-4 py-2"
                    }
                  >
                    {" "}
                    Privacy Policy{" "}
                  </button>
                  <button
                    onClick={() => setPageView("email")}
                    className={
                      view == "email"
                        ? "text-center morphic-attribute-button-pressed text-GREEN mx-2 md:mx-0 w-full  px-4 py-2"
                        : "text-center morphic-attribute-button text-GREY mx-2 w-full md:mx-0 px-4 py-2"
                    }
                  >
                    {" "}
                    Email Support{" "}
                  </button>
                </div>
                
              </div>
            </div>
              }
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Container;
